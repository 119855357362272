import React, { ReactElement, ReactNode } from "react";
import { Tooltip as MondayTooltip } from "monday-ui-react-core";



type ITooltipProps = {
  children: ReactElement;
  content: string | JSX.Element;
  showArrow?: boolean;
  className?: string;
  zIndex?: number;
  delay?: number;
  title?: string;
  arrowClassName?: string;
  image?: string;
}

function Tooltip({
  children,
  content,
  showArrow = true,
  className,
  zIndex,
  delay = 0,
  title,
  arrowClassName,
  image
}: ITooltipProps) {
  return (
    <MondayTooltip
      content={content}
      tip={showArrow}
      immediateShowDelay={0}
      className={className}
      zIndex={zIndex}
      hideWhenReferenceHidden
      showDelay={delay}
      title={title}
      arrowClassName={arrowClassName}
      image={image}
      // modifiers={[
      //   {
      //     name: 'preventOverflow',
      //     options: {
      //       mainAxis: false
      //     }
      //   },
      //   {
      //     name: 'flip',
      //     options: {
      //       fallbackPlacements: []
      //     }
      //   }
      // ]}
      // shouldShowOnMount
      withMaxWidth
    >
      {children}
    </ MondayTooltip>
  )
}

export default Tooltip;
