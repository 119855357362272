import React from "react";
import { BreadcrumbItem, BreadcrumbsBar, Dialog, DialogContentContainer, Text } from "monday-ui-react-core";
import { Robot } from "monday-ui-react-core/icons";

import Avatar from "../../../components/Avatar";
import Button from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import Toggle from "../../../components/Toggle";
import { ExpandIcon, InOutArrowIcon, UserIcon } from "../../../resources/icons";
import Tooltip from "../../../components/Tooltip";
import AccountSwitch from "./AccountSwitch";
import useUser from "../../../hooks/states/userHook";
import useGoogleConnection from "../../../hooks/services/googleHook";
import CustomEditableText from "../../../components/CustomEditableText";
import clsx from "clsx";



type INavigationsProps = {
  sheetName?: string;
  fileId?: string;
  activeFilePath?: string[];
  zoomLevel?: number;
  isReadonly?: boolean;
  onZoom?: (value: number) => void;
  onSelectFile?: () => void;
  onSyncBoardToSheet?: () => void;
  onFullScreenClick?: () => void;
  showAutomationPopup?: () => void;
  onEditFilename: (title: string) => void;
}

const ZoomOptions = [
  {
    value: 50,
    label: "50%"
  },
  {
    value: 75,
    label: "75%"
  },
  {
    value: 90,
    label: "90%"
  },
  {
    value: 100,
    label: "100%"
  },
  {
    value: 125,
    label: "125%"
  },
  {
    value: 150,
    label: "150%"
  },
  {
    value: 200,
    label: "200%"
  }
]

function Navigations({
  sheetName,
  fileId,
  activeFilePath,
  zoomLevel,
  isReadonly,
  onZoom,
  onSelectFile,
  onSyncBoardToSheet,
  onFullScreenClick,
  onEditFilename,
  showAutomationPopup
}: INavigationsProps) {
  const { userLogin } = useGoogleConnection();
  const {isGoogleConnected, GoogleDetails, appFeature} = useUser();
  const innerSheetName = sheetName || "<Sheet name>";
  

  const getZoomValue = () => {
    const val = ZoomOptions.find((z) => z.value == zoomLevel);
    if(val) return [val];
    return [];
  }

  const onLoadNewSheet = () => {
    if(isGoogleConnected) onSelectFile && onSelectFile();
    else userLogin();
  }


  const onRequestNewAccess = () => {
    const url = `https://docs.google.com/spreadsheets/d/${fileId}`
    window.open(url, '_blank')
  }
  

  return (
    <header className="w-full py-2 ps-[38px] pe-[30px] border border-grayed-light border-solid">
      <div className="w-full">
        {/* Heading */}
        <section className="flex w-full items-center gap-x-4">
          {/* Account Avatar & name */}
          <div>
            {isGoogleConnected? (
              <Dialog
                hideTrigger={[
                  Dialog.hideShowTriggers.CLICK,
                  Dialog.hideShowTriggers.CLICK_OUTSIDE
                ]}
                hideWhenReferenceHidden
                showTrigger={Dialog.hideShowTriggers.CLICK}
                position={Dialog.positions.BOTTOM_END}
                moveBy={{
                  main: -20,
                  secondary: -5
                }}
                content={
                  <DialogContentContainer>
                    <AccountSwitch />
                  </DialogContentContainer>
                }
                zIndex={10}
              >
                <button>
                  <div className="flex flex-col justify-center items-center gap-y-2">
                    <Avatar 
                      label={GoogleDetails.name?.fullName}
                      initials={GoogleDetails.initials}
                      className="text-white"
                      src={GoogleDetails.profile}
                    />

                    <Text
                      className="text-grayed text-xs font-normal poppins-normal"
                      align={Text.align.CENTER}
                    >
                      &gt; {GoogleDetails.name?.fullName}
                    </Text>
                  </div>
                </button>
              </Dialog>
            ) : (
              <Avatar 
                icon={UserIcon} 
                className="cursor-pointer"
                onClick={userLogin}
              />
            )}
          </div>

          {/* Sheet Name and Expansion */}
          <div className="flex flex-grow items-center justify-between">
            {/* File Name & Path */}
            <div>
              <CustomEditableText 
                isReadonly={!isGoogleConnected}
                isNav={true}
                value={innerSheetName}
                onValueChange={(v) => onEditFilename(v)}
                classes={{
                  textClassName: clsx(
                    "text-grayed text-2xl font-normal leading-normal",
                    "hover:border-2 hover:border-gray-500 truncate max-w-[650px]"
                  )}}
              />

              <BreadcrumbsBar
                  type={BreadcrumbsBar.types.INDICATION}
                  className="text-grayed text-xs font-normal monday-breadcrumb text-ellipsis"
                >
                  {/* Removes file name from path */}
                  {(activeFilePath?.slice(0, activeFilePath.length - 1)??[])
                    .map(((p, i) => (
                      <BreadcrumbItem key={p+i} text={p}/>
                    )))
                  }
                </BreadcrumbsBar>
            </div>

            <div>
              <Button
                variant="outlined"
                startIcon={ExpandIcon}
                onClick={onFullScreenClick}
                disabled={!isGoogleConnected}
              >
                Full Screen
              </Button>
            </div>
          </div>
        </section>

          {/* Actions */}
          <section className="mt-2 flex justify-between items-center">
            <div className="flex gap-2">
              <Button onClick={onLoadNewSheet}>Load New Sheet</Button>
              <Button variant="outlined" disabled={!isGoogleConnected} onClick={onSyncBoardToSheet}>Board to Sheet</Button>
              {
                //AppFeatureObject means that it is object view and we get it from monday context
                appFeature !== "AppFeatureObject" ?
                <Button className="gap-2 align-center" variant="outlined" disabled={!isGoogleConnected}  onClick={showAutomationPopup} ><Robot /> Automate</Button>
              : null
              }
              
              {
                isReadonly && 
                <Dialog
                hideTrigger={[
                  Dialog.hideShowTriggers.CLICK,
                  Dialog.hideShowTriggers.CLICK_OUTSIDE
                ]}
                hideWhenReferenceHidden
                showTrigger={Dialog.hideShowTriggers.CLICK}
                position={Dialog.positions.RIGHT_END}
                content={
                  <DialogContentContainer className="bg-blue-100 w-96 z-[999]">
                    <p>You can only view this document. To make changes ask the owner for edit access</p>
                    <Button onClick={onRequestNewAccess}>Request Access</Button>
                  </DialogContentContainer>
                }
              >
                <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-1.5 rounded">View only</span>
              </Dialog>
                
              }

              
            </div>

            <div className="flex gap-5 items-center">
              <div className="flex gap-x-2 items-center">
                <Text className="text-primary text-sm leading-5 font-normal">Zoom</Text>
                <Dropdown
                  options={ZoomOptions}
                  defaultValue={[ZoomOptions[-3]]}
                  className="min-w-[88px] z-10"
                  value={getZoomValue()}
                  onChange={(option) => {
                    const zoomValue = Array.isArray(option)
                      ? option[0].value : option.value;
                    onZoom && onZoom(typeof zoomValue == "number"? zoomValue : parseInt(zoomValue))
                  }}
                  disabled={!sheetName}
                />
              </div>
              
              <div className="flex gap-x-2 items-center">
                <Text className="text-primary text-sm leading-5 font-normal">2 Way Sync</Text>
                <InOutArrowIcon className="h-5 w-5 text-primary" />

                <Tooltip content="Available in upcoming version">
                  <Toggle />
                </Tooltip>
              </div>
            </div>
          </section>
      </div>
    </header>
  )
}

export default Navigations;
