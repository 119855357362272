import React, { useEffect, useState } from "react";
import _ from "lodash";
import { IoWarning } from "react-icons/io5";
import { Checkbox, Text } from "monday-ui-react-core";

import { CircledInfoIcon, DownloadIcon } from "../../../resources/icons";
import Button from "../../../components/Button";
import {
	useWorkspaceBoardsByAutoPagination,
} from "../../../hooks/monday/boardhooks";
import Logger from "../../../utilities/Logger";
import Dropdown, { IDropdownOption, MultiChoiceOptions } from "../../../components/Dropdown";
import useWorkspaceByAutoPagination from "../../../hooks/monday/workspaceHooks";
import { exportBoardToSheetRequest } from "../../../requests/boardViewRequests";
import useUser from "../../../hooks/states/userHook";
import { getAllBoardItemsAndHeaders, getBoardColumns } from "../../../hooks/monday/boardItemsHooks";


interface IBoardSelectionProps {
	isSheetActive?: boolean;
	onCancel?: () => void;
	onImport: (headers: string[], rows: (string | number | boolean | Date)[][]) => Promise<void>;
	onLimitExceeded?: () => void;
}

function BoardSelection({ 
	isSheetActive, 
	onCancel, 
	onImport, 
	onLimitExceeded 
}: IBoardSelectionProps) {
	const { workspaces, isLoading: workspaceLoading } = useWorkspaceByAutoPagination();
	const WorkSpaceOptions: IDropdownOption[] = workspaces.map((workspace) => ({
		label: workspace.name,
		value: workspace.id,
	}));
	const { userId } = useUser();
	const [workspaceID, setWorkspaceID] = useState<number | string | null>(0);
	const { boardOptions, isLoading: boardLoading } = useWorkspaceBoardsByAutoPagination(workspaceID, true);
	const [selectedBoard, setSelectedBoard] = useState("");
	const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
	const [isSubmitting, setSubmitting] = useState(false);
	const [exportAllColumns, setExportAllColumns] = useState(true);
	const [columnOptions, setColumnOptions] = useState<IDropdownOption[]>([]);
	const [isColumnsLoading, setColumnsLoading] = useState(false)


	useEffect(() => {

		return () => {
			setSelectedBoard("");
			setSelectedColumns([]);
			setColumnOptions([]);
			setWorkspaceID(0)
		}
	}, [])


	const handleCloseModal = () => {
		setSelectedBoard("");
		setWorkspaceID(0);
		setExportAllColumns(true);
		setSelectedColumns([]);
		setColumnOptions([]);
		onCancel ? onCancel(): null;
	}

	//this handles embedding selected board to the imported sheet
	const handleEmbedBoardToSheet = async () => {
		try {
			setSubmitting(true);

			if (selectedBoard) {
				const canExport = await exportBoardToSheetRequest(userId, selectedBoard);

				if(canExport) {
					const boardData = await getAllBoardItemsAndHeaders(selectedBoard, selectedColumns);
	
					if (boardData.items.length) {
						await onImport(
							boardData.headers,
							boardData.items
						);
						onCancel && onCancel();

					}
				} else {
					handleCloseModal();
					onLimitExceeded && onLimitExceeded();
				}
			}

		} catch (error) {
			Logger.error(error);
			
		} finally {
			setSubmitting(false);
		}
	};

	const onFetchColumns = async (boardId: string) => {
		try {
			setColumnsLoading(true);
			setSelectedColumns([]);
			const columns = await getBoardColumns(boardId);
	
			if(columns) {
				const options = columns.reduce<IDropdownOption[]>(
					(cum, cur) => [
						...cum, 
						{
							label: cur.title,
							value: cur.id,
							meta: {
								type: cur.type
							}
						}
					], []
				);
	
				setColumnOptions(options);
			}

		} finally {
			setColumnsLoading(false);
		}
	}


	return (
		<div className="w-full px-6 space-y-4">
			<div className="space-y-4">
				<Text className="text-grayed font-normal text-2xl leading-[22px] inline-flex items-center gap-2">
					Select board to export
					<DownloadIcon />
				</Text>

				<Text className="text-grayed text-wrap">
					Please select the board to export to Google Sheet and load into the embed view
				</Text>
			</div>

			<div className="space-y-3 h-80">
				<div className="relative w-full">

					{/* Workspace dropdown */}
					<p>Workspace</p>
					<Dropdown
						placeholder="Select workspace"
						options={WorkSpaceOptions}
						searchable={true}
						isLoading={workspaceLoading}
						value={WorkSpaceOptions.filter(e => e?.value == workspaceID)}
						className="min-w-[88px]"
						onChange={(option) => {
							const workspaceId = Array.isArray(option)
								? option[0].value.toString() : option.value.toString();
							setWorkspaceID(workspaceId);
							setSelectedBoard("");
							setColumnOptions([])
							setSelectedColumns([])
						}}
					/>
					
					{/* Boards dropdown */}
					<p className="mt-3">Board</p>
					<Dropdown
						placeholder={"Select Board"}
						options={boardOptions}
						noOptionsMessage={() => workspaceID? "No boards available" : "Select a workspace first"}
						isLoading={boardLoading}
						searchable={true}
						value={boardOptions.filter(e => e?.value == selectedBoard)}
						className="min-w-[88px] z-12 mb-3"
						onChange={(option) => {
							const boardId = Array.isArray(option)
								? option[0].value.toString() : option.value.toString();
							setSelectedBoard(boardId);
							// Fetch board columns
							onFetchColumns(boardId);
						}}
					/>

					{/* Columns */}
					<p className="mt-3">Columns</p>
					<div className="space-y-2 mb-2">
						<Checkbox
							checked={exportAllColumns}
							onChange={(e) => {
								const v = e.target.checked
								setExportAllColumns(v);

								// Set selected columns to empty list
								if(v) setSelectedColumns([])
							}}
							label="Export all columns"
						/>

						<Dropdown
							placeholder={exportAllColumns? "Export all columns" : "Select specific columns"}
							noOptionsMessage={
								() => selectedBoard? "No board columns available" : "Select a board first"
							}
							options={columnOptions}
							isLoading={isColumnsLoading && !exportAllColumns}
							searchable={true}
							value={columnOptions.filter(e => selectedColumns.includes(`${e.value}`))}
							className="min-w-[88px] z-12 mb-3"
							onChange={(options) => {
								if(!Array.isArray(options)) {
									const columnId = options?.value?.toString();
									setSelectedColumns([...selectedColumns, `${columnId}`])
								}	
							}}
							optionRenderer={MultiChoiceOptions}
							onOptionRemove={(option: any) => {
								const list = _.remove(selectedColumns, (i)=> i !== option?.value)
								setSelectedColumns(list);
							} }
							multiSelect
							multiline
							disabled={exportAllColumns}
						/>
					</div>
				</div>
				
				{/* Warning */}
				<div className="flex items-start gap-3 my-3">
					{isSheetActive ? (
						<>
							<CircledInfoIcon className="text-grayed h-8 w-8" />
							<Text className="text-grayed text-xs text-wrap mb-2">
								Exporting this board will share its data with a Google Sheet and display it in the
								embedded view, allowing seamless interaction with the data. <br />
							</Text>
						</>
					) : (
						<>
							<IoWarning className="h-8 w-8 text-red-500" />
							<Text className="text-xs text-wrap text-red-500">
								A sheet should be embedded or created to use this feature.
								<br />
								Feature requires an active sheet in preview
							</Text>
						</>
					)}
				</div>
			</div>

			{/* Footer */}
			<footer className="mt-5" style={{ marginTop: "50px" }}>
				<div className="w-full flex gap-x-4 items-center justify-between">
					<Button variant="outlined" onClick={handleCloseModal}>
						Cancel
					</Button>
					
					<Button
						loading={isSubmitting}
						disabled={isSubmitting || !isSheetActive}
						onClick={handleEmbedBoardToSheet}
					>
						Export to Sheet
					</Button>
				</div>
			</footer>
		</div>
	);
}

export default BoardSelection;
