import React, { ReactNode } from "react";
import {
  Dropdown as MondayDropdown,
  DropdownOption,
  Chips,
} from "monday-ui-react-core";
import clsx from "clsx";
import { Check } from "monday-ui-react-core/icons";



export type IDropdownOption = {
  label: string;
  value: string | number;
  meta?: {
    type?: string;
  };
}

type IDropdownProps = {
  className?: string;
  options?: Array<IDropdownOption>;
  placeholder?: string;
  defaultValue?: Array<IDropdownOption>;
  disabled?: boolean;
  searchable?: boolean;
  onChange?: (v: IDropdownOption | IDropdownOption[]) => void;
  value?: Array<IDropdownOption>;
  asyncOptions?: (inputValue: string) => Promise<IDropdownOption[]>;
  cacheOptions?: boolean;
  clearable?: boolean;
  closeOnSelect?: boolean;
  onFilter?: (option: any, inputValue: string) => boolean;
  overflowContainer?: boolean;
  overflowContainerWithAnimation?: boolean;
  isLoading?: boolean;
  loadingMessage?: (obj: { inputValue: string; }) => string;
  isVirtualized?: boolean;
  multiSelect?: boolean;
  multiline?: boolean;
  noOptionsMessage?: (obj: { inputValue: string; }) => string;
  onMenuClose?: () => void;
  onMenuOpen?: () => void;
  withGroupDivider?: boolean;
  optionRenderer?: (option: any) => JSX.Element;
  onOptionRemove?: (option: any) => void;
  valueRenderer?: ReactNode;
}

function Dropdown({
  className,
  placeholder,
  options,
  defaultValue,
  disabled,
  searchable,
  value,
  clearable = false,
  cacheOptions,
  closeOnSelect,
  overflowContainer,
  overflowContainerWithAnimation,
  isLoading,
  isVirtualized,
  multiSelect,
  multiline,
  withGroupDivider,
  valueRenderer,
  optionRenderer,
  noOptionsMessage,
  loadingMessage,
  asyncOptions,
  onChange,
  onFilter,
  onMenuClose,
  onMenuOpen,
  onOptionRemove
}: IDropdownProps) {
  return ( 
    <MondayDropdown
      className={clsx(
        // "dropdown-stories-styles_spacing",
        className
      )}
      defaultValue={defaultValue}
      clearable={clearable}      
      options={options}
      placeholder={placeholder}
      size={MondayDropdown.sizes.SMALL}
      disabled={disabled}
      onChange={onChange}
      onOptionSelect={onChange}
      searchable={searchable}
      value={value}
      maxMenuHeight={180}
      asyncOpttions={asyncOptions}
      cacheOptions={cacheOptions}
      closeMenuOnScroll={closeOnSelect}
      filterOption={onFilter}
      insideOverflowContainer={overflowContainer}
      insideOverflowWithTransformContainer={overflowContainerWithAnimation}
      isLoading={isLoading}
      loadingMessage={loadingMessage}
      isVirtualized={isVirtualized}
      // menuPlacement={MondayDropdown.menuPlacement.AUTO}
      multi={multiSelect}
      multiline={multiline}
      noOptionsMessage={noOptionsMessage}
      onMenuClose={onMenuClose}
      onMenuOpen={onMenuOpen}
      withGroupDivider={withGroupDivider}
      optionRenderer={optionRenderer}
      valueRenderer={valueRenderer}
      onOptionRemove={onOptionRemove}
      // onBlur={onBlur}
      // color={MondayDropdown.colors.POSITIVE}
    />
  )
}

export default Dropdown;



export function MultiChoiceOptions (props: any) {

  return (
    <div className="flex items-center gap-1 w-full h-full">
      <span>{props?.label}</span>
      {props?.meta?.type && (
        <span className={clsx(
          "bg-primary/70 text-white text-xs leading-none py-1 px-[0.4rem] rounded-2xl"
        )}>
          {props?.meta?.type}
        </span>
      )}
    </div>
  )
}