import React, { useEffect, useState } from "react";
import { Heading, Loader, Text } from "monday-ui-react-core";
import { Bolt, Close, Robot } from "monday-ui-react-core/icons";
import { ModalContent, Modal as MondayModal } from "monday-ui-react-core";
import clsx from "clsx";
import { AxiosError } from "axios";

import Navigations from "./sections/Navigations";
import Footer from "./sections/Footer";
import useUser from "../../hooks/states/userHook";
import DriveConnection from "./sections/DriveConnection";
import LoadSheet from "./sections/LoadSheet";
import useModal from "../../hooks/components/modalHook";
import FileSelection from "./sections/FileSelection";
import FilePreview from "./sections/FilePreview";
import { IFile } from "../../resources/types";
import BoardSelection from "./sections/BoardSelection";
import Button from "../../components/Button";
import useSheetFile from "../../hooks/states/fileHook";
import Logger from "../../utilities/Logger";
import NewSheet from "./sections/NewSheet";
import useGoogleConnection, { useGoogleDrive } from "../../hooks/services/googleHook";
import useToast from "../../hooks/components/toastHook";
import {
	embedSpreadsheetToBoardView,
	embedSpreadsheetToObjectView,
	getEmbedSpreadsheet,
	getObjectEmbedSpreadsheet,
} from "../../hooks/data/boardViewHook";
import MondayService from "../../services/MondayService";
import Carousel from "./sections/Carousel";
import useCrisp from "../../hooks/components/crispHook";



function FileUpload() {
	useGoogleConnection({ init: true });
	const {
		selectedFile,
		activeSheetTab,
		ActiveSheet,
		isLoading: isLoadingFile,
		sheetDoc,
		SheetList,
		isReadonly,
		FileOperationsToast,
		zoomLevel,
		updateSheet,
		deleteSheet,
		createNewSpreadsheet,
		onSelectFile,
		changeTabHandler,
		handleDataSync,
		createNewSheet,
		importBoardToSheet,
		updateSheetFileName,
		loadSpreadsheet,
		updateMetaData,
		updateZoomLevelHandler
	} = useSheetFile();
	const { 
		isGoogleConnected, 
		GoogleDetails, 
		boardId, 
		viewId, 
		workspaceId, 
		userId, 
		accountId,
		appFeature,
		updateUserContextDetails
	} = useUser();
	const { findFileById } = useGoogleDrive();
	const { userLogin } = useGoogleConnection();
	// For Memoised Modal State
	const {
		closeModal: closeMemoisedModal,
		MemoizedModalComponent,
		showModal: showMemoisedModal,
	} = useModal();
	// For regular modal
	const { closeModal, ModalComponent, showModal } = useModal();
	const { ToastComponent, showToast, closeToast } = useToast();
	const [fullScreen, setFullScreen] = useState(false);
	const [isLoadingView, setLoadingView] = useState(false);
	const [isRefreshing, setRefreshing] = useState(false);
	const [status, setStatus] = useState("");
	// const [zoomLevel, setZoomLevel] = useState(100);
	const [show, setShow] = useState(false);
	const [selectedFolderId, setSelectedFolderId] = useState("");
	useCrisp();


	
	const upgradePlan = async () => {
    const mondayService = new MondayService();
    mondayService.openPlanSelection();
	};

	const updateBoardContext = async () => {
		if(userId && accountId) {
			await updateUserContextDetails();
		}
	}

	const initBoardView = async () => {
		try {
			setLoadingView(true);
			
			if (boardId) {
				let boardViewData = undefined;
				if(appFeature == "AppFeatureObject")
					boardViewData = await getObjectEmbedSpreadsheet(userId, boardId, GoogleDetails.ref);
				else if(appFeature == "AppFeatureBoardView")
					boardViewData = await getEmbedSpreadsheet(userId, boardId, viewId ?? 0, GoogleDetails.ref);
				else
					Logger.info(`App feature ('${appFeature}') doesn't have supported data`);

				if (boardViewData) {
					const file = await findFileById(boardViewData.doc_id);
					if (file) {
						// dispatch(UPDATE_GOOGLE({
						//   token: boardViewData.google_token,
						// }));

						await onSelectFile({
							...file,
							path: [(file.path as string) || ""],
							kind: file.kind || "",
							mimeType: file.mimeType || "",
						});
					}
				}
			} else {
				// Syncs user's monday context with app
				await updateUserContextDetails();
			}

		} catch (error) {
			Logger.error(error);

		} finally {
			setLoadingView(false);
		}
	};

	const handleSheetDeletion = async (sheetId: number) => {
		try {
			setStatus("Deleting sheet ...");
			await deleteSheet(sheetId);

		} catch (error) {
			Logger.error(error);
		} finally {
			setStatus("");
		}
	};

	const handleCreateSpreadSheetDoc = async (title: string, file?: IFile) => {
		try {
			// Create Spreadsheet
			const doc = await createNewSpreadsheet(title);

			if (boardId && workspaceId && GoogleDetails?.token && doc) {
				// Check quota and link sheet to board
				let resp = null;
				if (appFeature == "AppFeatureObject") {
					resp = await embedSpreadsheetToObjectView(
						userId,
						boardId,
						workspaceId,
						doc.spreadsheetId,
						GoogleDetails.token,
						GoogleDetails.ref
					);
				} else {
					resp = await embedSpreadsheetToBoardView(
						userId,
						boardId,
						workspaceId,
						doc.spreadsheetId,
						GoogleDetails.token,
						viewId ?? 0,
						GoogleDetails.ref
					);
				}
				
				// Show error if denied or embed sheet to view
				if (resp == false) {
					showLimitationPopup("embedding");
				} else {
					await loadSpreadsheet(doc, file);
				}
			}

		} catch (error) {
			Logger.error(error);
			showToast({
				content: "Sorry, unable to create document. Try again",
			});
		}
	};

	const handleBoardImportToSheet = async (
		headers: string[],
		rows: (string | number | boolean | Date)[][]
	) => {
		try {
			setStatus("Importing board");
			const hasTransformedHeaders = await importBoardToSheet(headers, rows);

			if(hasTransformedHeaders) {
				showToast({
					content: "Some board headers got transformed due to duplications",
					type: "warning",
					position: {
						vertical: "top",
						horizontal: "center"
					},
					closeable: true,
					showIcon: true,
					duration: 8000,
				});
			}
		} catch (error: AxiosError | Error | any) {		
			// Large board columns	
			if(error?.message.includes("Sheet is not large enough")){
				showToast({
					content: error?.message,
					type: "error",
					position: {
						vertical: "top",
						horizontal: "center"
					},
					closeable: true,
					showIcon: true,
					duration: 10000,
					actionButton: {
						title: "Resize here",
						onClick: () => {
							const wrapperElem = document.getElementById("sheet-wrapper");

							if(wrapperElem) {
								wrapperElem.scrollTop = wrapperElem.scrollHeight;
							}

						}
					}
				});				
				
			}	else {
				showToast({
					content: "Sorry, unable to import board. Try again",
					type: "error",
					position: {
						vertical: "top",
						horizontal: "center"
					},
					closeable: true,
					showIcon: true,
				});
			}
		} finally {
			setStatus("");
		}
	};

	const showDeleteSheetWarning = (sheetId: number) => {
		showModal({
			show: true,
			width: "400px",
			showCloseButton: false,
			children: (
				<div>
					<Heading
						type={Heading.types.h2}
						size={Heading.sizes.MEDIUM}
						value="Heads Up!"
						className="!text-2xl"
					/>

					<div className="mt-5">Are you sure you want to delete this sheet?</div>

					<footer className="mt-7">
						<div className="w-full flex gap-x-4 items-center justify-end">
							<Button variant="outlined" onClick={closeModal}>
								Cancel
							</Button>
							<Button
								onClick={() => {
									handleSheetDeletion(sheetId);
									closeModal();
								}}
							>
								Yes, delete sheet
							</Button>
						</div>
					</footer>
				</div>
			),
		});
	};

	const closeMondayModal = () => {
		setShow(false);
	};

	// const showCreateNewSpreadSheetPopup = (folder?: string) => {
	// 	showModal({
	// 		show: true,
	// 		showCloseButton: false,
	// 		width: "430px",
	// 		sticky: false,
	// 		children: (
	// 			<NewSheet
	// 				onCancel={closeModal}
	// 				onCreateSpreadsheet={handleCreateSpreadSheetDoc}
	// 				folder={folder}
	// 			/>
	// 		),
	// 	});
	// };

	const showFileSelectionPopUp = () => {
		showMemoisedModal({
			showCloseButton: false,
			width: "600px",
			children: (
				<FileSelection
					onSelect={async (selectedFile: IFile) => {
						closeMemoisedModal();

						if (boardId && workspaceId && GoogleDetails?.token) {
							let resp = null;

							if (appFeature == "AppFeatureObject") {
								resp = await embedSpreadsheetToObjectView(
									userId,
									boardId,
									workspaceId,
									selectedFile.id,
									GoogleDetails.token,
									GoogleDetails.ref
								);
							} else {
								resp = await embedSpreadsheetToBoardView(
									userId,
									boardId,
									workspaceId,
									selectedFile.id,
									GoogleDetails.token,
									viewId ?? 0,
									GoogleDetails.ref
								);
							}

							if (resp == false) {
								showLimitationPopup("embedding");
							}

							onSelectFile(selectedFile);
						}
					}}
					onCreateSheet={(selectedFolder) => {
						closeMemoisedModal();
						setSelectedFolderId(selectedFolder ?? "");
						setShow(true);
						// setTimeout(() => {
						//   showCreateNewSpreadSheetPopup(selectedFolder);
						// }, 50);
					}}
					onCancel={closeMemoisedModal}
				/>
			)
		});
	};

	const showSyncBoardToSheetPopUp = () => {
		showMemoisedModal({
			showCloseButton: false,
      modalClassName: "p-3 pt-5",
			children: (
				<BoardSelection
					onCancel={closeMemoisedModal}
					isSheetActive={!!ActiveSheet}
					onImport={handleBoardImportToSheet}
					onLimitExceeded={() => showLimitationPopup("export")}
				/>
			)
		});
	};

	const showAutomationPopup = () => {
		showModal({
			showCloseButton: false,
			width: "600px",
			sticky: false,
      title: ' ',
      modalClassName: "p-1 pt-3",
			children: (
				<div className="w-full space-y-3">
					<div className="space-y-3">
            <div className="flex justify-end px-4 py-1">
              <Close size={14} cursor={'pointer'} onClick={()=>closeModal()}/>
            </div>
						<p className="w-full pt-0 text-grayed text-center font-normal text-3xl leading-[22px] inline-flex items-center gap-2 justify-center">
							<Robot size={35} className="pr-1"/>
							Let GSI work for you! 
						</p>

						<Text className="px-14 text-grayed text-wrap w-full text-center text-[16px]">
              Set it and forget it! <br />
							Automate your workflow with GSI&apos;s pre-made <span className="font-bold">automation recipes </span>
							&amp; <span className="font-bold">custom automations</span> tailored to your needs.
						</Text>
						<Carousel />
						<div className="flex justify-center mt-5 mb-5">
							<Button onClick={closeModal} className="p-2">Got It!</Button>
						</div>
            &nbsp;
					</div>
				</div>
			)
		});
	};

	const showLimitationPopup = (limitType: "embedding" | "export") => {
		switch(limitType) {
			case "embedding":
				showModal({
					show: true,
					width: "400px",
					showCloseButton: false,
					sticky: true,
					children: (
						<div>
							<div className="!text-2xl font-bold text-center">Attention</div>
		
							<div className="mt-5 text-center">
								You&apos;ve exceeded your plan {limitType} limit!
							</div>
		
							<footer className="mt-7">
								<div className="w-full flex gap-x-4 items-center justify-center">
									<Button onClick={upgradePlan}>
										Upgrade Now <Bolt />
									</Button>
								</div>
							</footer>
						</div>
					),
				});
			break

			case "export":
				showToast({
					type: "error",
					showIcon: true,
					closeable: true,
					duration: 5000,
					actionButton: {
						title: "Upgrade now!",
						onClick: () => {
							closeToast();
							upgradePlan();
						}
					},
					position: {
						vertical: "top",
						horizontal: "center"
					},
					content: `You've exceeded your plan ${limitType} limit!`,
				})
			break;

			default:
			break;
		}
	}

	const handleRefreshClick = async () => {
		try {
			setRefreshing(true);
			setStatus("Refreshing ...");
			if (ActiveSheet) await handleDataSync(ActiveSheet);

		} catch (error) {
			Logger.error(error);

		} finally {
			setRefreshing(false);
			setStatus("");
		}
	};

	const handleCreateSheet = async () => {
		try {
			setStatus("Creating new sheet ...");
			if (sheetDoc) {
				await createNewSheet(sheetDoc);
			}

		} catch (error) {
			Logger.error(error);
		} finally {
			setStatus("");
		}
	};

	const handleSheetTitleUpdate = async (sheetId: number, title: string) => {
		try {
			await updateSheet(sheetId, { title });

		} catch (error) {
			Logger.error(error);
		}
	};

	const switchSheetTab = async (tabIndex: number) => {
		changeTabHandler(tabIndex);
		updateMetaData(userId, {
			zoomLevel,
			activeSheet: tabIndex
		})
	}


	// Reset selected file state on google connect change
	useEffect(() => {
		if (!isGoogleConnected) {
			onSelectFile(undefined);

		} else {
			updateBoardContext();
		}
	}, [isGoogleConnected]);

	// Check if user accepted all permissions
	useEffect(() => {
		if (!GoogleDetails?.isAcceptAllPermissions) {
			if (GoogleDetails.token !== undefined) {
				showModal({
					show: true,
					width: "400px",
					showCloseButton: false,
					sticky: true,
					children: (
						<div>
							<Heading
								type={Heading.types.h2}
								size={Heading.sizes.MEDIUM}
								value="Attention!"
								className="!text-2xl"
							/>

							<div className="mt-5">You need to enable all persmissions to use this app!</div>

							<footer className="mt-7">
								<div className="w-full flex gap-x-4 items-center justify-end">
									<Button
										onClick={() => {
											userLogin();
											// window.open('https://myaccount.google.com/connections#filter=4', '_blank')
											// closeModal();
										}}
									>
										Add Permissions
									</Button>
								</div>
							</footer>
						</div>
					),
				});
			} else {
				closeModal();
			}
		} else {
			closeModal();
		}
	}, [GoogleDetails?.isAcceptAllPermissions]);

	useEffect(() => {
		initBoardView();

	}, []);

	
	return (
		<div className="min-w-max mx-auto flex flex-col h-full relative">
			{isLoadingView ? (
				<div className="h-full w-full flex items-center justify-center">
					<Loader size={Loader.sizes.MEDIUM} />
				</div>
			) : (
				<>
					{/* Navigation Bar */}
					{fullScreen ? (
						<div className="absolute top-1 right-1 z-10">
							<Button
								variant="outlined"
								// startIcon={ExpandIcon}
								onClick={() => setFullScreen(false)}
							>
								Exit Full Screen
							</Button>
						</div>
					) : (
						<Navigations
							zoomLevel={zoomLevel}
							onSelectFile={showFileSelectionPopUp}
							onSyncBoardToSheet={showSyncBoardToSheetPopUp}
							sheetName={selectedFile?.name}
							fileId={selectedFile?.id}
							activeFilePath={selectedFile?.path}
							onFullScreenClick={() => setFullScreen(true)}
							onZoom={updateZoomLevelHandler}
							onEditFilename={updateSheetFileName}
							isReadonly={isReadonly}
							showAutomationPopup={showAutomationPopup}
						/>
					)}

					{/* Sheet Content */}
					<div className="bg-grayed-light flex-grow flex justify-center items-center">
						{isGoogleConnected ? (
							<>
								{selectedFile ? (
									<FilePreview
										fullScreen={fullScreen}
										activeSheetData={ActiveSheet}
										activeSheet={activeSheetTab}
										isLoadingFile={isLoadingFile}
										tabList={SheetList}
										isSyncing={isRefreshing}
										onChangeTab={switchSheetTab}
										zoomLevel={zoomLevel}
										onAddSheet={handleCreateSheet}
										onDeleteSheet={showDeleteSheetWarning}
										updateSheet={handleSheetTitleUpdate}
										sheetStatus={status}
										isReadonly={isReadonly}
									/>
								) : (
									<LoadSheet onSelectFile={showFileSelectionPopUp} />
								)}
							</>
						) : (
							<DriveConnection />
						)}
					</div>

					{/* Footer */}
					{!fullScreen && (
						<Footer
							isDriveConnected={isGoogleConnected && !!ActiveSheet}
							isSyncing={isRefreshing}
							lastUpdate={
								selectedFile?.modifiedTime ? new Date(selectedFile.modifiedTime) : undefined
							}
							onRefresh={handleRefreshClick}
						/>
					)}

					<ToastComponent />
					{MemoizedModalComponent}
					<ModalComponent />
					{FileOperationsToast}
				</>
			)}

			{/* Create New Spreadsheet Modal */}
			<MondayModal
				contentSpacing
				title={" "}
				show={show}
				hideCloseButton
				alertDialog={false}
				width={"430px"}
				onClose={() => {
					setShow(false);
				}}
				description={""}
				classNames={{
					modal: clsx({ "monday-modal-hide-close-btn": true }),
				}}
			>
				<ModalContent>
					<NewSheet
						onCancel={closeMondayModal}
						onCreateSpreadsheet={handleCreateSpreadSheetDoc}
						folder={selectedFolderId}
					/>
				</ModalContent>
			</MondayModal>
		</div>
	);
}

export default React.memo(FileUpload);
