import useSWR from "swr";

import MondayService from "../../services/MondayService";
import Logger from "../../utilities/Logger";
import { 
  GET_ALL_BOARDS_QUERY_BY_LIMIT, 
  GET_ALL_BOARDS_QUERY_BY_WORKSPACE_AND_LIMIT, 
  GET_BOARD_ITEMS_QUERY, GET_USER_WORKSPACES 
} from "../../resources/queries/board";
import { IBoard, IColumn, IWorkspace } from "../../resources/types/responses";
import { useEffect, useState } from "react";



const mondayService = new MondayService();

//get all user workspaces
export async function getUserWorkspaces() {
  try {
    const response = await mondayService.query<{ workspaces: IWorkspace[] }>(
      GET_USER_WORKSPACES
    );
    return response.data.workspaces;
    
  } catch (error) {
    Logger.error(error);
    throw error;
  }
}

/**
 * Gets boards by workspaceId
 * @param workspaceId
 * @param onlyBoard filters list of type 'board'. Defaults to true.
 *  */ 
export async function getBoardsByWorkspaceId(
  workspaceId: number|string|null,
  onlyTypeBoard = true
) {
  const boards: IBoard[] = [];

  try {
    let page = 1;
    let boardFetchLength = 0;
    const limit = 100;

    do {
      const response = await mondayService.query<{ boards: IBoard[] }>(
        GET_ALL_BOARDS_QUERY_BY_WORKSPACE_AND_LIMIT(workspaceId, page, limit)
      );      

      boardFetchLength = response.data.boards.length
      
      if(onlyTypeBoard) { // Sets only board with type = 'board'
        const boardList = response.data.boards
          .filter(b => b.type == "board");
        boards.push(...boardList);

      } else { // sets all types of boards
        boards.push(...response.data.boards)
      }

      page++
      
    } while (boardFetchLength === limit);
    return boards;

  } catch (error) {
    Logger.error(error);
    return boards;
  }
}

//this gets all the boards a user has access to
async function getUserBoards() {
  try {
    let page = 1;
    const boards: IBoard[] = []
    let boardFetchLength = 0
    const limit = 100
    do {
      const response = await mondayService.query<{ boards: IBoard[] }>(
        GET_ALL_BOARDS_QUERY_BY_LIMIT(page, limit)
      );
      boardFetchLength = response.data.boards.length
      boards.push(...response.data.boards)
      page++
    } while (boardFetchLength === limit);


    return boards;

  } catch (error) {
    Logger.error(error);
    throw error;
  }
}

//this gets the items present in a particular board
export async function getUserBoardItems(boardId: string) {
  try {
    const response = await mondayService.query<{ boards: IBoard[] }>(
      GET_BOARD_ITEMS_QUERY(boardId)
    );
    return response.data.boards;

  } catch (error) {
    Logger.error(error);
    throw error;
  }
}

function useBoards() {
  const {
    data,
    isLoading
  } = useSWR(
    "getUserBoards",
    getUserBoards
  )


  return {
    boardList: data ?? [],
    isLoading
  }
}

export default useBoards;




const LIMIT = 100;
export function useWorkspaceBoardsByAutoPagination(
  workspaceId: string | number | null, 
  onlyTypeBoard = false
) {
  // State to store all fetched boards
  const [boards, setBoards] = useState<Array<IBoard>>([]);
  const boardIds = new Set(boards.reduce<string[]>((cum, cur) => [...cum, cur.id], []));
  const [isLoading, setLoading] = useState(false)

  const fetchData = async (wid: string | number | null, p = 1) => {
    let page = p;
    
    try {
      setLoading(true);
      let boardFetchLength = 0;

      do {
        // Fetch boards for the current page
        const response = await mondayService.query<{ boards: IBoard[] }>(
          GET_ALL_BOARDS_QUERY_BY_WORKSPACE_AND_LIMIT(wid, page, LIMIT)
        );
        
        boardFetchLength = response.data.boards.length;
      
        if(onlyTypeBoard) {
          // Filter boards of type 'board' if onlyTypeBoard is true
          const boardList = response.data.boards
            .filter(b => (b.type == "board") && !boardIds.has(b.id));
          setBoards(brds => [...brds, ...boardList]);
        } else {
          const boardList = response.data.boards
            .filter(b => !boardIds.has(b.id))
          setBoards(brds => [...brds, ...boardList]);
        }

        page++;
        
      // Continue fetching if we received the maximum number of boards (LIMIT)
      } while (boardFetchLength === LIMIT);


    } catch (error: any) {
      if(
        error?.errors?.[0]?.extensions?.code == "maxComplexityExceeded"
        || error?.extensions?.code == "maxComplexityExceeded"
      ) {
        // If complexity error occurs, retry after 30 seconds from the last successful page
        setTimeout(() => {
          fetchData(wid, page);
        }, 30000)
      } else {
        // Log any other errors
        Logger.error(error);
      }
    } finally {
      setLoading(false);
    }
  }

  // Trigger fetchData when workspaceId or onlyTypeBoard changes
  useEffect(() => {
    setBoards([]);
    fetchData(workspaceId);
  }, [workspaceId, onlyTypeBoard])
  
  return {
    boards,
    // Create board options for dropdown or other UI components
    boardOptions: boards.map((board) => ({
      label: board.name,
      value: board.id,
    })),
    isLoading
  }
}
