import clsx from "clsx";
import React, { useState } from "react";
import { AddIcon, DeleteIcon, RemoveIcon } from "../../../../resources/icons";
import CustomEditableText from "../../../../components/CustomEditableText";
// import { EditableText } from "monday-ui-react-core";

type Props = {
  sheets?: Array<{
    title: string;
    id: number;
  }>
  isReadonly?: boolean;
  activeSheet: number;
  onChange?: (value: number) => void;
  onAddNewSheet?: () => void;
  onDeleteSheet?: (sheetId: number) => void;
  onUpdateSheet?: (sheetId: number, sheetName: string) => void;
}

function SheetTabs({
  sheets = [],
  activeSheet,
  isReadonly,
  onChange,
  onAddNewSheet,
  onDeleteSheet,
  onUpdateSheet
}: Props) {


  const handleTitleUpdate = (sheetId: number, value: string) => {
    onUpdateSheet && onUpdateSheet(sheetId, value);
  }

  return (
    <div className="flex overflow-x-auto bg-white w-full">
      {/* Add Button */}
      <div 
        className={clsx(
          "cursor-pointer text-xs text-grayed border",
          "border-solid border-gray-300 rounded-t-md py-1 px-2",
          isReadonly? "bg-gray-300 cursor-auto" : "hover:bg-primary/80 hover:text-white"
          
        )}
        aria-disabled={isReadonly}
        onClick={() => {
          if(!isReadonly) {
            onAddNewSheet && onAddNewSheet();
          }
          
        }}
      >
        <AddIcon className="h-4 w-4" />
      </div>
      
      {sheets.map((sheet, i) => (
        <div 
          key={sheet.id} 
          className={clsx(
            "cursor-pointer text-xs text-grayed border relative group flex justify-between items-center",
            "border-solid border-gray-300 rounded-t-md py-1 px-1 min-w-[80px] max-w-[115px] gap-x-1",
            {"bg-primary text-white": activeSheet == i}
          )}
          onClick={() => {
            onChange && onChange(i);
          }}
        >
          <CustomEditableText 
            value={sheet.title}
            onValueChange={(v) => handleTitleUpdate(sheet.id, v)}
            classes={{textClassName: "max-w-[90px]"}}
            isReadonly={isReadonly}
          />
    
          {!isReadonly && (
            <span 
              onClick={(e) => {
                e.stopPropagation();
                onDeleteSheet && onDeleteSheet(sheet.id);
              }}
              className="invisible group-hover:visible"
            >
              <RemoveIcon className={clsx(
                "w-3 h-3",
                activeSheet == i? "text-white" : "text-red-500"
              )}/>
            </span>
          )}
        </div>
      ))}
    </div>
  )
}

export default SheetTabs;
