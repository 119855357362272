import { GoogleSpreadsheet,  } from "google-spreadsheet";
import _ from "lodash";
import { AxiosError } from "axios";
import { gapi } from "gapi-script";

import Logger from "../utilities/Logger";
import GoogleDriveService from "./GoogleDriveService";



const googleDriveService = new GoogleDriveService();

class GoogleSheetService {
  
  constructor() {
    this.getSheetData = this.getSheetData.bind(this);
  }
  
  async getSheetData(fileId: string, token: string): Promise<{doc: GoogleSpreadsheet}> {
    try {
      const doc = new GoogleSpreadsheet(fileId, {token});
      await doc.loadInfo(true);
    
      return {doc};
      
    } catch (error: AxiosError | Error | any) {
      Logger.error(error);
      throw error;
    }
  }

  // async getExcelSheetData(): Promise<{doc: GoogleSpreadsheet}> {
  async getExcelSheetData(fileId: string, name: string, token: string) {
    try {
      const copiedFile = await googleDriveService.copyFile(fileId, name, token);
      const doc = await this.getSheetData(copiedFile.id, token);
      return {...doc, file: copiedFile};
      
    } catch (error) {
      Logger.error(error);
      throw error;
    }
  }

  async createSheetData(fileName: string, token: string): Promise<GoogleSpreadsheet> {
    try {
      const doc = await GoogleSpreadsheet.createNewSpreadsheetDocument({token}, { 
        title: fileName,
      });
      
      return doc;

    } catch (error: AxiosError | Error | any) {
      if((error as AxiosError)?.status == 401) {
        throw error;
        
      } else {
        Logger.error(error);
        throw error;
      }
    }
  }

  async addMetadata (
    spreadsheetId: string, 
    userId: string,
    data: Record<string, any>, 
    token?: string 
  ) {
    const requestBody = {
      requests: [
        {
          createDeveloperMetadata: {
            developerMetadata: {
              metadataId: userId,
              metadataKey: userId,
              metadataValue: JSON.stringify(data),
              location: {
                spreadsheet: true
              },
              visibility: "PROJECT"
            }
          }
        },
      ],
      includeSpreadsheetInResponse: true
    };

    try {
      if(token) gapi.client.setToken({access_token: token});

      await gapi.client.request({
        path: `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}:batchUpdate`,
        method: "POST",
        body: requestBody
      });
      
    } catch (error) {
      console.error('Error updating metadata:', error);
    }
  }

  async updateMetadata (
    spreadsheetId: string, 
    userId: string,
    data: Record<string, any>, 
    token?: string 
  ) {
    const requestBody = {
        requests: [
          {
            updateDeveloperMetadata: {
              dataFilters: [
                {
                  developerMetadataLookup: {
                    metadataId: userId,
                    metadataKey: userId,
                    visibility: "PROJECT"
                  }
                }
              ],
              developerMetadata: {
                metadataKey: userId,
                metadataValue: JSON.stringify(data),
                location: {
                  spreadsheet: true
                },
                visibility: "PROJECT"
              },
              fields: "*"
            }
          },
        ],
        includeSpreadsheetInResponse: true
    };

    try {
      if(token) gapi.client.setToken({access_token: token});

      await gapi.client.request({
        path: `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}:batchUpdate`,
        method: "POST",
        body: requestBody
      });
      
    } catch (error) {
      console.error('Error updating metadata:', error);
    }
  }

  async getMetadata (
    metadataId: string,
    spreadsheetId: string,
    token?: string 
  ) {
    try {
      if(token) gapi.client.setToken({access_token: token});

      const response = await gapi.client.request({
        path: `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/developerMetadata/${metadataId}`,
        method: "GET",
      });

      if((response.status??0) >= 200 && (response?.status??0)<= 299) {
        const value = response.result?.metadataValue;

        if(value) return JSON.parse(value);
        return null
      }
      
    } catch (error) {
      console.error('Error updating metadata:', error);
    }
  }
}

export default GoogleSheetService;