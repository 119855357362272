import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import _ from "lodash";
import {
	BreadcrumbItem,
	BreadcrumbsBar,
	ExpandCollapse,
	Icon,
	IconButton,
	ListItem,
	Loader,
	Skeleton,
	Text,
	TextField,
} from "monday-ui-react-core";

import Avatar from "../../../components/Avatar";
import Button from "../../../components/Button";
import { FolderIcon, GridIcon, SharedIcon, SortIcon, StarredIcon } from "../../../resources/icons";
import { MIME_TYPES } from "../../../services/GoogleDriveService";
import { IFile } from "../../../resources/types";
import useUser from "../../../hooks/states/userHook";
import { useDriveFiles, useDriveSubFiles } from "../../../hooks/services/googleHook";
import RefreshIcon from "../../../resources/icons/RefreshIcon";
import Tooltip from "../../../components/Tooltip";
import clsx from "clsx";
import { MdWarningAmber } from "react-icons/md";



type IFileTree = {
	parentFolder: IFile;
	subFiles?: Array<IFile>;
	selectedFileId?: string;
	onSelectFile: (file: IFile) => void;
	onFolderClick?: (path: string[], id: string) => void;
};

function FileTree({
	parentFolder,
	subFiles = [],
	selectedFileId,
	onSelectFile,
	onFolderClick,
}: IFileTree) {
	const { isLoading, filesData } = useDriveSubFiles(
		parentFolder.mimeType == MIME_TYPES.FOLDER ? parentFolder.id : undefined
	);
	const [isCollapsed, setCollapsed] = useState(false);
	const SubFilesWithPath: Array<IFile> =
		filesData.map((file) => ({
			...file,
			path: [...(parentFolder?.path ?? []), file.name],
		})) ?? [];

	return (
		<>
      {
        parentFolder.mimeType == MIME_TYPES.FOLDER || parentFolder.mimeType == '' ? (
					<ExpandCollapse
						title={
							<div className="col-span-8 flex items-center gap-x-3">
								{parentFolder?.icon ? parentFolder.icon : <FolderIcon className="w-4 h-4" />}
								<Text className="text-sm">{parentFolder?.name}</Text>
							</div>
						}
						open={isCollapsed}
						hideBorder
						headerClassName="py-1 px-4"
						contentClassName="py-1"
						onClick={() => {
							setCollapsed(!isCollapsed);

							if (parentFolder.path) onFolderClick && onFolderClick(parentFolder.path, parentFolder.id);
						}}
					>
						{isLoading ? (
							<Skeleton
								type={Skeleton.types.TEXT}
								size={Skeleton.sizes.TEXT.H2}
								className="my-2 w-full"
								width={493}
							/>
						) : (
							<>
								{_.sortBy(subFiles.length ? subFiles : SubFilesWithPath, "mimeType")
								.map((file) =>
									file.mimeType == MIME_TYPES.FOLDER ? (
										<FileTree
											key={file.id}
											parentFolder={file}
											onSelectFile={onSelectFile}
											selectedFileId={selectedFileId}
											onFolderClick={onFolderClick}
										/>
									) : (
										<ListItem
											key={file.id}
											onClick={() => {
												file.mimeType !== MIME_TYPES.EXCEL_SHEET? onSelectFile(file) : null;
											}}
											selected={selectedFileId == file.id}
										>
											<div className={clsx(
												"w-full grid grid-cols-12 text-xs gap-2",
												{"text-gray-400": file.mimeType == MIME_TYPES.EXCEL_SHEET}
											)}>
												<div className="col-span-8 truncate overflow-clip flex gap-1 items-center">
													{file.name}

													{file.mimeType == MIME_TYPES.EXCEL_SHEET && (
														<Tooltip
															showArrow
															zIndex={10000}
															className="text-xs bg-white text-gray-600 text-pretty text-start max-w-80"
															arrowClassName="!bg-white"
															title="Notice"
															image="/xlsx-conversion.png"
															content={
																<div className="">
																	<p>
																		This file is not in Google Sheets format. To embed it, open the file in Google Sheets 
																		and save it as a Google Sheet.
																	</p>
																</div>
															}
														>
															<Icon 
																icon={MdWarningAmber} 
																className="text-orange-500 w-4 h-4"
															/>
														</Tooltip>
													)}
												</div>
												<div className="col-span-4">
													{(file?.createdTime || file?.modifiedTime) &&
														format(
															new Date(
																(file?.modifiedTime ? file.modifiedTime : file.createdTime) as string
															),
															"LLL d, yyyy"
														)}
												</div>
											</div>
										</ListItem>
									)
								)}
							</>
						)}

					</ExpandCollapse> 
				) : (
					// Parent folder
					<ListItem
						key={parentFolder.id}
						onClick={() => {
							onSelectFile(parentFolder);
						}}
						selected={selectedFileId == parentFolder.id}
					>
						<div className="w-full grid grid-cols-12 text-xs">
							<div className="col-span-8 block">{parentFolder.name}</div>
							<div className="col-span-4">
								{(parentFolder?.createdTime || parentFolder?.modifiedTime) &&
									format(
										new Date(
											(parentFolder?.modifiedTime ? parentFolder.modifiedTime : parentFolder.createdTime) as string
										),
										"LLL d, yyyy"
									)}
							</div>
						</div>
					</ListItem>
				)
      }
			
		</>
	);
}

type IFileSelectionProps = {
	onSelect: (file: IFile) => void; //should trigger when a sheet is selected
	onCancel?: () => void; //should trigger when it's cancelled
	onCreateSheet?: (selectedFolder?: string) => void; //should trigger creation of sheet
};

function FileSelection({ onSelect, onCancel, onCreateSheet }: IFileSelectionProps) {
	const { GoogleDetails } = useUser();
	const { isRefreshing, isLoading, filesData, refreshFiles } = useDriveFiles();
	const [selectedFile, setSelectedFile] = useState<IFile>();
	const RootFolder: Array<IFile & { files?: Array<IFile> }> = [
		{
			id: "drive",
			name: "My Drive",
			icon: <FolderIcon className="w-4 h-4" />,
			path: ["My Drive"],
			kind: "",
			mimeType: "",
			createdTime: "",
			files: filesData
				.filter((file) => !file.shared && !file.starred)
				.map((file) => ({
					...file,
					path: ["My Drive", file.name],
				})),
		},
		{
			id: "shared",
			name: "Shared with me",
			icon: <SharedIcon className="w-4 h-4" />,
			path: ["Shared with me"],
			mimeType: "",
			files: filesData
				.filter((file) => file.shared)
				.map((file) => ({
					...file,
					path: ["Shared with me", file.name],
				})),
		},
		{
			id: "starred",
			name: "Starred",
			icon: <StarredIcon className="w-4 h-4" />,
			path: ["Starred"],
			mimeType: "",
			files: filesData
				.filter((file) => file.starred)
				.map((file) => ({
					...file,
					path: ["Starred", file.name],
				})),
		},
	];
	const [folders, setFolders] = useState<Array<IFile & { files?: Array<IFile> }>>(RootFolder);	

	
	const onEmbedSheet = () => {
		if (selectedFile) {
			onSelect(selectedFile);
		}
	};

	const handleOnCreateSpreadSheet = () => {
		if (selectedFile) {
			if (selectedFile.mimeType == MIME_TYPES.FOLDER) {
				onCreateSheet && onCreateSheet(selectedFile.id);
				return;
			} else {
				onCreateSheet && onCreateSheet(selectedFile?.parents?.[0]);
				return;
			}
		}

		onCreateSheet && onCreateSheet();
	};

	const searchFilesByName = (searchTerm: string) => {
		if (searchTerm.trim().length == 0) {
			return setFolders(RootFolder);
		}
		const result: any[] = [];

		const search = (files: any, path: any) => {
			for (const file of files) {
				if (file.name.toLowerCase().includes(searchTerm.toLowerCase())) {
					result.push({ ...file, fullPath: [...path, file.name] });
				}

				if (file.mimeType === "application/vnd.google-apps.folder") {
					// If the file is a folder, recursively search within it
					search(file.files || [], [...path, file.name]);
				}
			}
		};

		for (const drive of RootFolder) {
			search(drive.files, drive.path);
		}
		setFolders(result);
		return result;
	};


	useEffect(() => {
		setFolders(RootFolder)
	}, [filesData]);


	return (
		<div className="w-full">
			{/* Breadcrumb and User Avatar */}
			<div className="flex w-full items-center justify-between">
				<div className="space-y-4">
					<Text className="text-grayed font-normal text-2xl leading-[22px]">
						Select a Sheet to embed.
					</Text>

					<BreadcrumbsBar
						type={BreadcrumbsBar.types.INDICATION}
						className="text-grayed text-xs font-normal monday-breadcrumb text-ellipsis"
					>
						{(selectedFile?.path ?? [])?.map((p, i) => <BreadcrumbItem key={p + i} text={p} />)}
					</BreadcrumbsBar>
				</div>

				<div className="flex items-center justify-center flex-col gap-2">
					<Avatar
						label={GoogleDetails.name?.fullName}
						initials={GoogleDetails.initials}
						className="text-white"
						src={GoogleDetails.profile}
					/>

					<Text
						className="text-grayed text-xs font-normal poppins-normal"
						align={Text.align.CENTER}
					>
						{GoogleDetails.name?.fullName}
					</Text>
				</div>
			</div>

			{/* Search & Filters */}
			<div>
				<div className="flex items-center justify-between gap-x-5 mt-4">
					<div className="min-w-[calc((2_/3)_*_100%)]">
						<TextField
							placeholder="Search by name"
							size="small"
							onChange={(e) => searchFilesByName(e)}
						/>
					</div>

					<div className="flex">
						<IconButton
							icon={isRefreshing ? null : RefreshIcon}
							iconClassName="stroke-black"
							kind={IconButton.kinds.SECONDARY}
							size="small"
							loading={isRefreshing}
							disabled={isRefreshing}
							// hideTooltip
							ariaLabel="refresh files"
							tooltipProps={{
								zIndex: 10000,
							}}
							onClick={refreshFiles}
						/>
						<IconButton
							icon={GridIcon}
							iconClassName="!stroke-1"
							kind={IconButton.kinds.SECONDARY}
							size="small"
							ariaLabel="Grid view"
							tooltipProps={{
								zIndex: 10000,
							}}
							disabled
						/>
						<IconButton
							icon={SortIcon}
							iconClassName="!stroke-1"
							kind={IconButton.kinds.SECONDARY}
							ariaLabel="Sort files"
							size="small"
							tooltipProps={{
								zIndex: 10000,
							}}
							disabled
						/>
					</div>
				</div>
			</div>

			{/* Content / List */}
			<div className="h-80 mt-4 border-y border-grayed border-solid max-h-80 overflow-y-auto">
				{isLoading ? (
					<div className="w-full flex justify-center items-center h-full">
						<Loader size={Loader.sizes.SMALL} />
					</div>
				) : (
					<>
						{/* Empty List view */}
						{_.isEmpty(filesData) ? (
							<div className="w-full py-8">
								<Text className="text-center text-2xl text-grayed">No file(s) found in Drive</Text>
								<Text className="text-center text-grayed-light">Create a new sheet in drive </Text>
							</div>
						) : (
							<>
								{/* Heading */}
								<div className="w-full grid grid-cols-12 py-2 px-4">
									<div className="col-span-8">
										<Text className="font-semibold">Name</Text>
									</div>
									<div className="col-span-4">
										<Text className="font-semibold">Last Modified</Text>
									</div>
								</div>

								{folders.map((rootFolder) => (
									<FileTree
										key={rootFolder.id}
										parentFolder={rootFolder}
										subFiles={rootFolder.files}
										onSelectFile={setSelectedFile}
										selectedFileId={selectedFile?.id}
										onFolderClick={(path, id) => {
											setSelectedFile({
												// ...selectedFile,
												id,
												name: "",
												mimeType: MIME_TYPES.FOLDER,
												path,
											});
										}}
									/>
								))}
							</>
						)}
					</>
				)}
			</div>

			{/* Footer */}
			<footer className="mt-4">
				<div className="w-full flex gap-x-4">
					<Button variant="outlined" onClick={onCancel}>
						Cancel
					</Button>
					<Button variant="outlined" onClick={handleOnCreateSpreadSheet}>
						Create New Sheet
					</Button>
					<Button 
						disabled={
							!(selectedFile && selectedFile.mimeType == MIME_TYPES.SPREAD_SHEET)
						} 
						onClick={onEmbedSheet}
					>
						Embed Sheet
					</Button>
				</div>
			</footer>
		</div>
	);
}

export default FileSelection;
